import { SystemStyleObject } from '@chakra-ui/react';

const solid: SystemStyleObject = {
  fontSize: '16px',
  color: 'complementary.white',
  backgroundColor: 'complementary.primary',
  padding: '12px 24px',
  height: 'auto',
  _disabled: {
    backgroundColor: 'complementary.lightGrey',
    opacity: 1,
  },
  _hover: {
    backgroundColor: 'complementary.hoverBlue',
    _disabled: {
      backgroundColor: 'complementary.lightGrey',
    },
  },
};

const outlined: SystemStyleObject = {
  fontSize: '16px',
  color: 'complementary.primary',
  borderColor: 'complementary.primary',
  backgroundColor: 'complementary.white',
  border: 'solid 1px',
  padding: '12px 24px',
  height: 'auto',
  _disabled: {
    color: 'complementary.lightGrey',
    opacity: 'unset',
  },
  _hover: {
    backgroundColor: 'complementary.lightBlue',
    _disabled: {
      backgroundColor: 'complementary.lightGrey',
    },
  },
};

const outlinedBlack: SystemStyleObject = {
  fontSize: '16px',
  color: 'complementary.black',
  borderColor: 'complementary.black',
  backgroundColor: 'complementary.white',
  border: 'solid 1px',
  padding: '12px 24px',
  height: 'auto',
  _disabled: {
    color: 'complementary.lightGrey',
    opacity: 'unset',
  },
  _hover: {
    backgroundColor: 'complementary.lightBlue',
    _disabled: {
      backgroundColor: 'complementary.lightGrey',
    },
  },
};

const link: SystemStyleObject = {
  fontSize: '16px',
  color: 'complementary.primary',
  padding: '12px 24px',
  height: 'auto',
  _disabled: {
    color: 'complementary.superLightPurple',
  },
  _hover: {
    color: 'complementary.hoverBlue',
    textDecoration: 'none',
    _disabled: {
      color: 'complementary.superLightPurple',
    },
  },
};

const Button = {
  baseStyle: {
    borderRadius: '4px',
    lineHeight: '24px',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  variants: {
    solid,
    outlined,
    outlinedBlack,
    link,
  },
};

export default Button;
