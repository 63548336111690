import * as yup from 'yup';
import { Box, Button, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import Input from 'components/Form/Input';
import PasswordInput from 'components/Form/PasswordInput';
import { useAuth } from 'context/AuthContext';
import { useFormWithSchema } from 'utils/formHooks';
import notify from 'utils/notify';
import { ERROR_MESSAGES } from 'utils/form';

const SCHEMA = yup.object({
  email: yup
    .string()
    .email(ERROR_MESSAGES.invalidEmail)
    .required(ERROR_MESSAGES.required),
  password: yup.string().required(ERROR_MESSAGES.required),
});

function LoginForm() {
  const { signIn } = useAuth();

  const methods = useFormWithSchema(SCHEMA, {
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const {
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await signIn(data);
      notify('success', 'Zalogowano pomyślnie');
    } catch (err) {
      notify('error', 'Nieprawidłowe dane logowania');
    }
  });

  return (
    <FormProvider {...methods}>
      <Box as="form" onSubmit={onSubmit} w="100%">
        <VStack spacing={8}>
          <Input
            name="email"
            label="Adres e-mail"
            placeholder="Twój adres"
            autoComplete="email"
          />
          <PasswordInput
            name="password"
            label="Hasło"
            placeholder="Wpisz hasło"
            autoComplete="current-password"
          />
          <Button
            isLoading={isSubmitting}
            isDisabled={!isDirty || !isValid}
            type="submit"
            w="100%"
          >
            ZALOGUJ SIĘ
          </Button>
        </VStack>
      </Box>
    </FormProvider>
  );
}

export default LoginForm;
