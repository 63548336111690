import { Icon, IconProps } from '@chakra-ui/react';

function Logo(props: IconProps) {
  return (
    <Icon
      width="83"
      height="18"
      viewBox="0 0 83 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_333_4332)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.9461 17.7847H81.2339C81.2339 17.5522 81.2339 17.35 81.2339 17.1477C81.2338 15.0033 81.2379 12.8588 81.2323 10.7144C81.2257 8.12351 79.3907 6.18884 76.9494 6.18849C74.549 6.18815 72.7034 8.1316 72.6917 10.6898C72.6818 12.8341 72.6896 14.9787 72.6894 17.123C72.6894 17.3281 72.6894 17.5332 72.6894 17.7769H71.041C71.0245 17.6262 70.9983 17.4987 70.9982 17.3712C70.9964 15.0559 70.9732 12.7404 71.0047 10.4257C71.0454 7.4428 73.2022 4.922 76.0194 4.50416C78.9809 4.06479 81.7626 5.80165 82.6477 8.6799C82.8238 9.25236 82.9496 9.8663 82.9588 10.4633C82.9951 12.7969 82.9755 15.1315 82.975 17.4656C82.975 17.5575 82.9588 17.6494 82.9461 17.7847Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65.9524 17.7802H64.3343C64.3343 14.962 64.1024 12.1538 64.3949 9.40438C64.7344 6.21357 67.8712 4.06993 71.038 4.4938V6.20241C70.7519 6.20241 70.4976 6.19979 70.2434 6.20286C67.8099 6.23237 65.9895 8.0874 65.9592 10.6136C65.9337 12.7389 65.953 14.8647 65.9524 16.9903C65.9524 17.2347 65.9524 17.4791 65.9524 17.7802Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.1181 16.1946C58.9178 16.2485 61.1728 13.9954 61.2218 11.0954C61.269 8.29585 59.0631 5.95074 56.3258 5.89069C53.5378 5.82951 51.2242 8.07046 51.173 10.8818C51.1194 13.8263 53.273 16.1399 56.1181 16.1946ZM49.6436 10.9821C49.6594 7.26077 52.6007 4.2753 56.2371 4.2896C59.8599 4.30401 62.7879 7.3272 62.7794 11.0444C62.7707 14.781 59.8027 17.8224 56.1763 17.8106C52.5472 17.7987 49.6278 14.7471 49.6436 10.9821Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.7741 16.0509V17.891C33.9333 17.7833 33.1012 17.7722 32.3212 17.5594C29.9078 16.9003 28.0724 14.6422 28.019 12.0892C27.9364 8.14354 27.9877 4.195 27.9844 0.247603C27.9844 0.175475 28.011 0.103462 28.0313 0H29.638V4.38802C30.2447 4.38802 30.792 4.42278 31.3331 4.37811C31.8533 4.33515 32.2515 4.50368 32.6259 4.86147C33.0514 5.26814 33.5169 5.63071 34.089 6.11805H29.7034C29.6891 6.37648 29.6687 6.57565 29.6686 6.77483C29.6679 8.36892 29.6671 9.96313 29.6741 11.5572C29.6854 14.1446 31.5256 16.029 34.0558 16.0506C34.2739 16.0525 34.4919 16.0509 34.7741 16.0509Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5815 4.37988C15.1701 4.87201 15.6572 5.24324 16.0957 5.66769C16.2272 5.79497 16.2716 6.07026 16.2732 6.27832C16.2867 8.00596 16.2777 9.73382 16.2819 11.4616C16.2883 14.0979 18.1259 16.0598 20.5728 16.049C23.0014 16.0383 24.8181 14.0885 24.822 11.4828C24.8251 9.3562 24.8227 7.22976 24.8227 5.10332C24.8227 4.89719 24.8227 4.69095 24.8227 4.43993H26.4845C26.5006 4.56994 26.5335 4.71329 26.5338 4.85674C26.5361 7.13507 26.5677 9.41409 26.5244 11.6916C26.4766 14.2131 25.3427 16.111 23.1281 17.1963C20.9619 18.258 18.8417 18.005 16.9117 16.5315C15.6077 15.5359 14.8611 14.1631 14.6358 12.5051C14.5901 12.168 14.5844 11.8233 14.5837 11.4822C14.5795 9.16808 14.5815 6.85397 14.5815 4.37988Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.54037 16.1953C9.34741 16.2053 11.5798 13.9166 11.5799 11.0286C11.5799 8.19106 9.33866 5.89519 6.56331 5.88982C3.78375 5.88459 1.53732 8.16691 1.52854 11.0049C1.51959 13.9011 3.72524 16.1852 6.54037 16.1953ZM6.57982 4.28957C10.2068 4.29802 13.1335 7.30719 13.1345 11.0291C13.1356 14.7668 10.1739 17.8171 6.54935 17.8111C2.91907 17.8051 -0.0143006 14.7533 5.24484e-05 10.9974C0.0142895 7.2697 2.94589 4.28104 6.57982 4.28957Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.2178 17.6897V0H38.0118C38.0232 9.88997 38.0288 15.559 38.0287 17.007C38.0287 17.2119 38.0287 17.4169 38.0287 17.6897H36.2178Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.2178 17.9999C36.2178 15.4764 36.2178 13.0401 36.2178 10.691C36.2178 7.16725 38.3597 5.73269 38.5646 5.58442C40.5207 4.16909 42.6018 3.92819 44.722 5.05562C46.8367 6.18004 47.9479 8.05586 47.9751 10.5698C48.0018 13.0248 47.9809 15.4803 47.9809 17.981H46.3325C46.3221 17.7587 46.3029 17.5371 46.3027 17.3156C46.3002 15.0698 46.317 12.824 46.2958 10.5783C46.2752 8.38443 44.8543 6.61265 42.8447 6.21818C40.8675 5.82993 38.8881 6.9547 38.1678 8.94422C37.9717 9.48605 37.8868 10.1009 37.878 10.6843C37.8451 12.8911 37.8644 15.0987 37.8642 17.3061C37.8642 17.5144 37.8642 17.7227 37.8642 17.9999H36.2178Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_333_4332">
          <rect width="83" height="18" fill="currentColor" />
        </clipPath>
      </defs>
    </Icon>
  );
}

export default Logo;
