import { Box } from '@chakra-ui/react';
import FormButtons, { FormButtonsProps } from 'components/FormButtons';
import { SIDEBAR_WIDTH } from 'components/Layout/constants';

function FormFooter(props: FormButtonsProps) {
  return (
    <Box
      position="fixed"
      bottom={0}
      left={SIDEBAR_WIDTH}
      zIndex={10}
      width={`calc(100vw - ${SIDEBAR_WIDTH}px);`}
      display="flex"
      alignItems="center"
      backgroundColor="complementary.white"
      borderTop="solid 1px #eef2fb"
      padding="16px 32px"
    >
      <FormButtons {...props} />
    </Box>
  );
}

export default FormFooter;
