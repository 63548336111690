import { Box } from '@chakra-ui/react';
import LoadingSpinner from 'components/LoadingSpinner';
import { FormBodyProps } from 'types/common';
import DropzoneField from 'components/Form/Dropzone';

function FormBody({ isLoading }: FormBodyProps) {
  return (
    <Box w="100%">
      {isLoading && <LoadingSpinner />}
      <DropzoneField name="file" title="Dodaj plik CSV" acceptCsv isRemovable />
    </Box>
  );
}

export default FormBody;
