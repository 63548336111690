const Checkbox = {
  parts: ['control'],
  baseStyle: {
    control: {
      borderColor: 'complementary.primary',
      borderRadius: '4px',
      borderWidth: '1px',
      _checked: {
        color: 'complementary.primary',
        borderColor: 'complementary.primary',
        backgroundColor: 'complementary.lightBlue',
        _hover: {
          backgroundColor: 'complementary.lightBlue',
          borderColor: 'complementary.primary',
        },
      },
    },
    label: {
      marginLeft: '8px',
    },
  },
  sizes: {
    sm: {
      control: {
        h: '12px',
        w: '12px',
      },
      label: {
        fontSize: 'sm',
      },
      icon: {
        fontSize: '0.45rem',
      },
    },
    md: {
      control: {
        w: '18px',
        h: '18px',
      },
      label: {
        fontSize: 'md',
      },
      icon: {
        fontSize: '0.5rem',
      },
    },
    lg: {
      control: {
        w: '20px',
        h: '20px',
      },
      label: {
        fontSize: 'lg',
      },
      icon: {
        fontSize: '0.625rem',
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
};

export default Checkbox;
