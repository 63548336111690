const ROUTES = {
  auth: {
    login: '/login',
  },
  coupons: {
    base: '/coupons',
    create: '/coupons/create',
    statistics: '/coupons-statistics',
  },
  users: '/users',
  qrCodes: {
    generator: '/qr-codes-generator',
  },
  couponPools: '/coupon-pools',
};

export const DEFAULT_ROUTE = ROUTES.coupons.base;

export default ROUTES;
