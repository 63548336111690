import { useState } from 'react';
import * as yup from 'yup';
import { FormProvider } from 'react-hook-form';
import { Box, Button, HStack, Stack } from '@chakra-ui/react';
import { useFormWithSchema } from 'utils/formHooks';
import Card from 'components/Card';
import MainLayout from 'components/Layout/MainLayout';
import Input from 'components/Form/Input';
import QrCodePreview from 'components/QrCodePreview';

const schema = yup.object({
  wsmId: yup.string().nullable().required(),
});

function QrCodesGenerator() {
  const [qrCodeValue, setQrCodeValue] = useState<string>('');

  const methods = useFormWithSchema(schema, {
    mode: 'onChange',
    defaultValues: {
      wsmId: '',
    },
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const onSubmit = handleSubmit(async ({ wsmId }) => setQrCodeValue(wsmId));

  return (
    <MainLayout title="Generator kodów QR" headerButtons={[]}>
      <Stack spacing={4}>
        <FormProvider {...methods}>
          <Card as="form" onSubmit={onSubmit}>
            <HStack spacing={4}>
              <Input
                name="wsmId"
                placeholder="Wprowadź identyfikator sklepu"
                hideError
              />
              <Button type="submit" isDisabled={!isValid} flexShrink={0}>
                Generuj
              </Button>
            </HStack>
          </Card>
        </FormProvider>
        {qrCodeValue && (
          <Card>
            <Box maxWidth="400px" marginX="auto">
              <QrCodePreview qrCodeValue={qrCodeValue} />
            </Box>
          </Card>
        )}
      </Stack>
    </MainLayout>
  );
}

export default QrCodesGenerator;
