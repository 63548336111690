const Modal = {
  parts: ['dialog', 'body', 'header'],
  baseStyle: {
    dialog: {
      borderRadius: '6px',
    },
    body: {
      padding: '24px 48px',
    },
    header: {
      textAlign: 'center',
      padding: 0,
      marginBottom: '24px',
    },
  },
  sizes: {
    xs: {
      dialog: {
        maxW: 'xs',
      },
    },
    sm: {
      dialog: {
        maxW: 'sm',
      },
    },
    md: {
      dialog: {
        maxW: 'md',
      },
    },
    lg: {
      dialog: {
        maxW: 'lg',
      },
    },
    xl: {
      dialog: {
        maxW: 'xl',
      },
    },
    '2xl': {
      dialog: {
        maxW: '2xl',
      },
    },
    '3xl': {
      dialog: {
        maxW: '3xl',
      },
    },
    '4xl': {
      dialog: {
        maxW: '4xl',
      },
    },
    '5xl': {
      dialog: {
        maxW: '5xl',
      },
    },
    '6xl': {
      dialog: {
        maxW: '1270px',
      },
    },
    full: {
      dialog: {
        maxW: '100vw',
        minH: '100vh',
        '@supports(min-height: -webkit-fill-available)': {
          minH: '-webkit-fill-available',
        },
        my: 0,
        borderRadius: 0,
      },
    },
  },
  defaultProps: {
    isCentered: true,
  },
};

export default Modal;
