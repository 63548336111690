import * as yup from 'yup';
import { ERROR_MESSAGES, LIMITS } from 'utils/form';

enum USER_ROLES {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

const CREATE_SCHEMA = yup.object({
  email: yup
    .string()
    .required(ERROR_MESSAGES.required)
    .email(ERROR_MESSAGES.invalidEmail),
  password: yup
    .string()
    .required(ERROR_MESSAGES.required)
    .min(LIMITS.minPasswordLength, ERROR_MESSAGES.minPasswordLength),
  role: yup.string().required(ERROR_MESSAGES.required),
  apiKey: yup.string().nullable(),
});

const EDIT_SCHEMA = yup.object({
  password: yup
    .string()
    .min(LIMITS.minPasswordLength, ERROR_MESSAGES.minPasswordLength)
    .nullable()
    .transform((value) => value || null),
  apiKey: yup.string().nullable(),
});

export { USER_ROLES, CREATE_SCHEMA, EDIT_SCHEMA };
