import { useCallback, memo, useState, useEffect } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { useDropzone, FileRejection, DropEvent } from 'react-dropzone';
import {
  Button,
  FormControl,
  Text,
  FormErrorMessage,
  Box,
  IconButton,
  VStack,
} from '@chakra-ui/react';
import Delete from 'components/Icons/Delete';

interface DropzoneProps {
  name: string;
  onChange: (...event: any[]) => void;
  error: FieldError | undefined;
  title?: string;
  isRemovable?: boolean;
  acceptCsv: boolean;
}

function Dropzone({
  name,
  onChange,
  error,
  title,
  isRemovable,
  acceptCsv,
}: DropzoneProps) {
  const [file, setFile] = useState<File | null>(null);
  const { setValue, getValues } = useFormContext();
  const currentValue = getValues(name);

  const onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => void = useCallback(
    (acceptedFiles) => {
      setFile(acceptedFiles[0]);
      setValue(name, acceptedFiles[0], { shouldValidate: true });
    },
    [name, setValue],
  );

  useEffect(() => {
    if (currentValue?.url) setFile(null);
  }, [currentValue]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      ...(acceptCsv && {
        'text/csv': [],
      }),
    },
  });

  return (
    <FormControl isInvalid={Boolean(error)} pb="10px" {...getRootProps()}>
      <Box
        border="1px solid"
        borderRadius="6px"
        textAlign="center"
        borderColor={error ? 'complementary.error' : 'transparent'}
        minHeight={184}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        backgroundColor="complementary.lightBlue"
      >
        <input {...getInputProps({ onChange })} />
        {file ? (
          <VStack spacing={2}>
            <Text>{file.name}</Text>
            {isRemovable && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setFile(null);
                  setValue(name, null, { shouldValidate: true });
                }}
                aria-label="delete file"
                variant="solidSecondary"
                icon={<Delete />}
                isRound
              />
            )}
          </VStack>
        ) : (
          <>
            <Text
              fontWeight={600}
              mb={4}
              dangerouslySetInnerHTML={{
                __html: title || 'Dodaj plik',
              }}
            />

            {isDragActive ? (
              <Text lineHeight={4}>Upuść plik tutaj...</Text>
            ) : (
              <>
                <Text>Przeciągnij i upuść lub dodaj z dysku</Text>
                <Button type="button" variant="link" mt={6} p={0}>
                  DODAJ Z DYSKU
                </Button>
              </>
            )}
          </>
        )}
      </Box>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}

Dropzone.defaultProps = {
  title: null,
  isRemovable: false,
};

interface DropzoneFieldProps {
  name: string;
  title?: string;
  isRemovable?: boolean;
  acceptCsv?: boolean;
}

function DropzoneField({
  name,
  title,
  isRemovable,
  acceptCsv = false,
}: DropzoneFieldProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <Dropzone
          name={name}
          onChange={(e: any) => onChange(e.target.files[0])}
          error={error}
          title={title}
          isRemovable={isRemovable}
          acceptCsv={acceptCsv}
        />
      )}
    />
  );
}
DropzoneField.defaultProps = {
  title: null,
  isRemovable: false,
  acceptCsv: false,
};
export default memo(DropzoneField);
