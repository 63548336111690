import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

interface TableWrapperProps extends BoxProps {
  children: React.ReactNode;
}

function TableWrapper({ children, ...props }: TableWrapperProps) {
  return (
    <Box
      overflow="auto"
      bgColor="complementary.white"
      padding="0px 16px"
      borderRadius="8px 8px 0px 0px"
      {...props}
    >
      {children}
    </Box>
  );
}

export default TableWrapper;
