const Drawer = {
  parts: ['dialog', 'header', 'closeButton', 'body', 'footer'],
  baseStyle: {
    dialog: {
      padding: '32px 32px 24px 32px',
    },
    header: {
      padding: '0px',
      marginBottom: '24px',
    },
    closeButton: { position: 'unset', width: '24px', height: '24px' },
    body: {
      padding: '0px',
    },
    footer: { padding: '24px 0px' },
  },
  sizes: {
    xs: {
      dialog: {
        maxW: 'xs',
      },
    },
    sm: {
      dialog: {
        maxW: 'md',
      },
    },
    md: {
      dialog: {
        maxW: '516px',
      },
    },
    lg: {
      dialog: {
        maxW: '3xl',
      },
    },
    xl: {
      dialog: {
        maxW: '4xl',
      },
    },
    full: {
      dialog: {
        maxW: '100vw',
        h: '100vh',
      },
    },
  },
};

export default Drawer;
