import { Icon, IconProps } from '@chakra-ui/react';

function Error(props: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_1_858"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1_858)">
        <path
          d="M12.0001 22.0251C11.7334 22.0251 11.4793 21.9751 11.2376 21.8751C10.9959 21.7751 10.7751 21.6334 10.5751 21.4501L2.5501 13.4251C2.36676 13.2251 2.2251 13.0043 2.1251 12.7626C2.0251 12.5209 1.9751 12.2668 1.9751 12.0001C1.9751 11.7334 2.0251 11.4751 2.1251 11.2251C2.2251 10.9751 2.36676 10.7584 2.5501 10.5751L10.5751 2.5501C10.7751 2.3501 10.9959 2.20426 11.2376 2.1126C11.4793 2.02093 11.7334 1.9751 12.0001 1.9751C12.2668 1.9751 12.5251 2.02093 12.7751 2.1126C13.0251 2.20426 13.2418 2.3501 13.4251 2.5501L21.4501 10.5751C21.6501 10.7584 21.7959 10.9751 21.8876 11.2251C21.9793 11.4751 22.0251 11.7334 22.0251 12.0001C22.0251 12.2668 21.9793 12.5209 21.8876 12.7626C21.7959 13.0043 21.6501 13.2251 21.4501 13.4251L13.4251 21.4501C13.2418 21.6334 13.0251 21.7751 12.7751 21.8751C12.5251 21.9751 12.2668 22.0251 12.0001 22.0251ZM12.0001 20.0251L20.0251 12.0001L12.0001 3.9751L3.9751 12.0001L12.0001 20.0251ZM11.0001 13.0001H13.0001V7.0001H11.0001V13.0001ZM12.0001 16.0001C12.2834 16.0001 12.5209 15.9043 12.7126 15.7126C12.9043 15.5209 13.0001 15.2834 13.0001 15.0001C13.0001 14.7168 12.9043 14.4793 12.7126 14.2876C12.5209 14.0959 12.2834 14.0001 12.0001 14.0001C11.7168 14.0001 11.4793 14.0959 11.2876 14.2876C11.0959 14.4793 11.0001 14.7168 11.0001 15.0001C11.0001 15.2834 11.0959 15.5209 11.2876 15.7126C11.4793 15.9043 11.7168 16.0001 12.0001 16.0001Z"
          fill="#EF476F"
        />
      </g>
    </Icon>
  );
}

export default Error;
