import styled from '@emotion/styled';

const Th = styled.th`
  padding: 16px 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  border-bottom: solid 1px #eef2fb;

  :last-of-type {
    text-align: right;
  }

  :first-of-type,
  :last-of-type {
    padding-inline: 0px;
  }
`;

export default Th;
