import { Icon, IconProps } from '@chakra-ui/react';

function QR(props: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_280_2233"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_280_2233)">
        <path
          d="M2.5 7.01923V2.50003H7.0192V4.00001H3.99998V7.01923H2.5ZM2.5 21.5V16.9808H3.99998V20H7.0192V21.5H2.5ZM16.9808 21.5V20H20V16.9808H21.5V21.5H16.9808ZM20 7.01923V4.00001H16.9808V2.50003H21.5V7.01923H20ZM17.2211 17.2211H18.5769V18.5769H17.2211V17.2211ZM17.2211 14.5096H18.5769V15.8654H17.2211V14.5096ZM15.8654 15.8654H17.2211V17.2211H15.8654V15.8654ZM14.5096 17.2211H15.8654V18.5769H14.5096V17.2211ZM13.1538 15.8654H14.5096V17.2211H13.1538V15.8654ZM15.8654 13.1539H17.2211V14.5096H15.8654V13.1539ZM14.5096 14.5096H15.8654V15.8654H14.5096V14.5096ZM13.1538 13.1539H14.5096V14.5096H13.1538V13.1539ZM18.5769 5.42311V10.8462H13.1538V5.42311H18.5769ZM10.8461 13.1539V18.5769H5.42308V13.1539H10.8461ZM10.8461 5.42311V10.8462H5.42308V5.42311H10.8461ZM9.65383 17.3846V14.3462H6.61538V17.3846H9.65383ZM9.65383 9.65386V6.61541H6.61538V9.65386H9.65383ZM17.3846 9.65386V6.61541H14.3461V9.65386H17.3846Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}

export default QR;
