import { SystemStyleObject } from '@chakra-ui/react';

const h1: SystemStyleObject = {
  fontSize: '32px',
  fontWeight: 600,
  lineHeight: '40px',
};

const h2: SystemStyleObject = {
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '32px',
};

const h3: SystemStyleObject = {
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '28px',
};

const h4: SystemStyleObject = {
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '28px',
};

const Text = {
  baseStyle: {
    fontWeight: 400,
    lineHeight: '24px',
  },
  variants: {
    h1,
    h2,
    h3,
    h4,
  },
};

export default Text;
