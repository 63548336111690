import Select from 'components/Form/Select';
import { useGetCouponPoolsOptions } from 'api/couponPools';

interface CouponPoolSelectProps {
  name: string;
  showLabel?: boolean;
  showError?: boolean;
}

function CouponPoolSelect({
  name,
  showLabel,
  showError,
}: CouponPoolSelectProps) {
  const { isFetching, data: options = [] } = useGetCouponPoolsOptions();

  return (
    <Select
      label={showLabel ? 'Pula' : ''}
      placeholder="Wybierz pulę"
      name={name}
      isLoading={isFetching}
      options={options}
      showError={showError}
      isClearable
    />
  );
}

CouponPoolSelect.defaultProps = {
  showLabel: false,
  showError: false,
};

export default CouponPoolSelect;
