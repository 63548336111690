import { Box, Button, ButtonProps, HStack, Text } from '@chakra-ui/react';
import { map } from 'lodash';
import { FOOTER_HEIGHT } from 'components/Footer';

type ListingActionButton = ButtonProps & {
  label: string;
  to?: string;
};

interface MainLayoutProps {
  children: React.ReactNode;
  title: string;
  headerButtons: ListingActionButton[];
  filterBody?: React.ReactNode;
}

function MainLayout({
  title,
  headerButtons,
  children,
  filterBody,
}: MainLayoutProps) {
  return (
    <Box w="100%" mb={FOOTER_HEIGHT}>
      <HStack justify="space-between" mb="52px">
        <Text variant="h1">{title}</Text>
        <HStack spacing="8px">
          {map(headerButtons, ({ label, ...buttonProps }, idx) => (
            <Button key={`button-${label}-${idx}`} {...buttonProps}>
              {label}
            </Button>
          ))}
        </HStack>
      </HStack>
      {filterBody && filterBody}
      {children}
    </Box>
  );
}

MainLayout.defaultProps = {
  filterBody: null,
};

export default MainLayout;
