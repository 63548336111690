import { Box, BoxProps } from '@chakra-ui/react';
import FeatureSearch from 'components/Icons/FeatureSearch';
import Placeholder from 'components/Placeholder';
import Tr from 'components/Table/Tr';

type EmptyBodyProps = BoxProps & {
  columnAmount: number;
  name: string;
};

function EmptyBody({ columnAmount, name, ...props }: EmptyBodyProps) {
  return (
    <Tr>
      <td colSpan={columnAmount}>
        <Box {...props}>
          <Placeholder
            icon={<FeatureSearch />}
            title="Brak danych"
            subtitle={`Dodaj ${name}, aby wyświetlić`}
          />
        </Box>
      </td>
    </Tr>
  );
}

export default EmptyBody;
