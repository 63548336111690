import * as yup from 'yup';
import { ERROR_MESSAGES } from 'utils/form';

const BODY_SCHEMA = yup.object({
  file: yup.mixed().required(ERROR_MESSAGES.required),
});

const DEFAULT_VALUES = {
  file: undefined,
};

export { BODY_SCHEMA, DEFAULT_VALUES };
