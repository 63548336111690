const Tag = {
  variants: {
    outlined: {
      container: {
        borderColor: 'complementary.placeholder',
        color: 'complementary.placeholder',
        backgroundColor: 'complementary.white',
      },
    },
    solid: {
      container: {
        borderColor: 'transparent',
        backgroundColor: 'complementary.lightBlue',
        color: 'complementary.primary',
      },
    },
  },
  baseStyle: {
    container: {
      fontSize: '14px',
      lineHeight: '20px',
      padding: '4px 12px',
      borderRadius: '8px',
      border: 'solid 1px',
      _hover: {
        backgroundColor: 'complementary.lightBlue',
        border: 'solid 1px',
        borderColor: 'complementary.primary',
        color: 'complementary.primary',
      },
    },
    closeButton: {
      marginStart: '4px',
      opacity: 1,
      cursor: 'pointer',
    },
  },
};

export default Tag;
