import { Button, HStack } from '@chakra-ui/react';

export interface FormButtonsProps {
  onCancel: () => void;
  isLoading: boolean;
  isDisabled: boolean;
  formId: string;
}

function FormButtons({
  onCancel,
  isLoading,
  isDisabled,
  formId,
}: FormButtonsProps) {
  return (
    <HStack gap={2} w="100%">
      <Button variant="link" onClick={onCancel}>
        Anuluj
      </Button>
      <Button
        type="submit"
        form={formId}
        isLoading={isLoading}
        isDisabled={isDisabled}
      >
        Zapisz i wróć
      </Button>
    </HStack>
  );
}

export default FormButtons;
