import { Icon, IconProps } from '@chakra-ui/react';

function VisibilityOff(props: IconProps) {
  return (
    <Icon
      width="25px"
      height="24px"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_19_2163"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="24"
      >
        <rect x="0.650635" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_19_2163)">
        <path
          d="M16.7506 13.3L15.3006 11.85C15.4506 11.0667 15.2256 10.3333 14.6256 9.64999C14.0256 8.96665 13.2506 8.69999 12.3006 8.84999L10.8506 7.39999C11.134 7.26665 11.4215 7.16665 11.7131 7.09999C12.0048 7.03332 12.3173 6.99999 12.6506 6.99999C13.9006 6.99999 14.9631 7.43749 15.8381 8.31249C16.7131 9.18749 17.1506 10.25 17.1506 11.5C17.1506 11.8333 17.1173 12.1458 17.0506 12.4375C16.984 12.7292 16.884 13.0167 16.7506 13.3ZM19.9506 16.45L18.5006 15.05C19.134 14.5667 19.6965 14.0375 20.1881 13.4625C20.6798 12.8875 21.1006 12.2333 21.4506 11.5C20.6173 9.81666 19.4215 8.47916 17.8631 7.48749C16.3048 6.49582 14.5673 5.99999 12.6506 5.99999C12.1673 5.99999 11.6923 6.03332 11.2256 6.09999C10.759 6.16665 10.3006 6.26665 9.85063 6.39999L8.30063 4.84999C8.98397 4.56665 9.68397 4.35415 10.4006 4.21249C11.1173 4.07082 11.8673 3.99999 12.6506 3.99999C15.1673 3.99999 17.409 4.69582 19.3756 6.08749C21.3423 7.47916 22.7673 9.28332 23.6506 11.5C23.2673 12.4833 22.7631 13.3958 22.1381 14.2375C21.5131 15.0792 20.784 15.8167 19.9506 16.45ZM20.4506 22.6L16.2506 18.45C15.6673 18.6333 15.0798 18.7708 14.4881 18.8625C13.8965 18.9542 13.284 19 12.6506 19C10.134 19 7.8923 18.3042 5.92563 16.9125C3.95897 15.5208 2.53397 13.7167 1.65063 11.5C2.00063 10.6167 2.4423 9.79582 2.97563 9.03749C3.50897 8.27915 4.1173 7.59999 4.80063 6.99999L2.05063 4.19999L3.45063 2.79999L21.8506 21.2L20.4506 22.6ZM6.20063 8.39999C5.7173 8.83332 5.27564 9.30832 4.87563 9.82499C4.47563 10.3417 4.13397 10.9 3.85063 11.5C4.68397 13.1833 5.8798 14.5208 7.43813 15.5125C8.99647 16.5042 10.734 17 12.6506 17C12.984 17 13.309 16.9792 13.6256 16.9375C13.9423 16.8958 14.2673 16.85 14.6006 16.8L13.7006 15.85C13.5173 15.9 13.3423 15.9375 13.1756 15.9625C13.009 15.9875 12.834 16 12.6506 16C11.4006 16 10.3381 15.5625 9.46313 14.6875C8.58813 13.8125 8.15063 12.75 8.15063 11.5C8.15063 11.3167 8.16313 11.1417 8.18813 10.975C8.21313 10.8083 8.25063 10.6333 8.30063 10.45L6.20063 8.39999Z"
          fill="black"
        />
      </g>
    </Icon>
  );
}

export default VisibilityOff;
