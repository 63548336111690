import { Icon, IconProps } from '@chakra-ui/react';

function Discount(props: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_280_2232"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_280_2232)">
        <path
          d="M13.9038 21.0538C13.6161 21.341 13.2595 21.4846 12.8342 21.4846C12.4088 21.4846 12.0526 21.341 11.7654 21.0538L2.96537 12.2538C2.82051 12.109 2.70673 11.9422 2.62405 11.7536C2.54135 11.565 2.5 11.3638 2.5 11.15V4.00001C2.5 3.59104 2.64776 3.23881 2.94328 2.94331C3.23878 2.64779 3.59101 2.50003 3.99998 2.50003H11.15C11.3564 2.50003 11.5541 2.54031 11.7431 2.62088C11.9321 2.70143 12.096 2.80986 12.2346 2.94618L21.0346 11.7616C21.3282 12.0552 21.4766 12.4138 21.4798 12.8375C21.483 13.2612 21.341 13.6166 21.0538 13.9038L13.9038 21.0538ZM12.8303 20L19.9846 12.85L11.1543 4.00001H3.99998V11.15L12.8303 20ZM6.49885 7.74998C6.84575 7.74998 7.14099 7.62856 7.38458 7.38573C7.62816 7.1429 7.74995 6.84803 7.74995 6.50113C7.74995 6.15423 7.62853 5.85899 7.3857 5.61541C7.14287 5.37182 6.848 5.25003 6.5011 5.25003C6.1542 5.25003 5.85896 5.37145 5.61538 5.61428C5.37179 5.85711 5.25 6.15198 5.25 6.49888C5.25 6.84578 5.37142 7.14102 5.61425 7.38461C5.85708 7.62819 6.15195 7.74998 6.49885 7.74998Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}

export default Discount;
