import { Icon, IconProps } from '@chakra-ui/react';

function ChevronRight(props: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_4_884"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4_884)">
        <path
          d="M12.9 12L8.30005 7.4L9.70005 6L15.7 12L9.70005 18L8.30005 16.6L12.9 12Z"
          fill="#050517"
        />
      </g>
    </Icon>
  );
}

export default ChevronRight;
