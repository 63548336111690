import styled from '@emotion/styled';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CloseButton from 'components/Toast/CloseButton';

const StyledContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    width: fit-content;
  }
  .Toastify__toast {
    text-align: left;
    align-items: center;
    padding: 8px;
    gap: 8px;
    box-shadow: unset;
    border-radius: 8px;
    width: fit-content;
    max-width: 446px;
    min-height: unset;
  }
  .Toastify__toast-body {
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 0;
  }
  .Toastify__toast--success {
    color: #050517;
    background: #9af6d5;
  }
  .Toastify__toast--error {
    color: #050517;
    background: #f4bbc9;
  }
`;

function CustomToastContainer() {
  return (
    <StyledContainer
      closeButton={<CloseButton />}
      hideProgressBar
      position="bottom-right"
      autoClose={5000}
    />
  );
}

export default CustomToastContainer;
