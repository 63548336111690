import { FormProvider, useForm } from 'react-hook-form';
import useQueryParams from 'utils/useQueryParams';
import {
  POOL,
  ONLY_VALID,
  ARCHIVED,
  PAGE,
  ONLY_AVAILABLE,
  VALUE,
  VALUE_OPTIONS,
} from 'pages/Coupons/Listing/constants';
import FilterBox from 'components/FilterBox';
import CouponPoolSelect from 'components/CouponPoolSelect';
import Switch from 'components/Form/Switch';
import Select from 'components/Form/Select';

function FilterBody() {
  const { search, setSearch } = useQueryParams();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      couponPoolId: search.get(POOL),
      value: search.get(VALUE),
      onlyValid: search.get(ONLY_VALID) === 'true',
      onlyAvailable: search.get(ARCHIVED) === 'false',
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const { couponPoolId, value, onlyValid, onlyAvailable } = data;

    if (couponPoolId) search.set(POOL, couponPoolId);
    else search.delete(POOL);

    if (value) search.set(VALUE, value);
    else search.delete(VALUE);

    if (onlyValid) search.set(ONLY_VALID, 'true');
    else search.delete(ONLY_VALID);

    if (onlyAvailable) search.set(ARCHIVED, 'false');
    else search.delete(ARCHIVED);

    search.set(PAGE, '1');
    setSearch(search);
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} id="filter-form">
        <FilterBox
          filterForm={
            <>
              <CouponPoolSelect name={POOL} />
              <Select
                name={VALUE}
                placeholder="Wartość"
                options={VALUE_OPTIONS}
                isClearable
              />
              <Switch name={ONLY_VALID} label="Tylko ważne" />
              <Switch name={ONLY_AVAILABLE} label="Tylko niewykorzystane" />
            </>
          }
        />
      </form>
    </FormProvider>
  );
}

export default FilterBody;
