const extendedColors = {
  complementary: {
    primary: '#0B24FB',
    secondary: '#9191E9',
    richBlack: '#050517',
    lightBlue: '#EBEDFB',
    placeholder: '#637381',
    hoverBlue: '#0C1FC9',
    lightGreyBlue: '#B0B3C7',
    lightPurple: '#B7B5E4',
    superLightPurple: '#F7F9FC',
    lightGrey: '#CFCCD6',
    outline: '#E0E0E0',
    black: '#000000',
    white: '#FFFFFF',
    success: '#0CCA4A',
    error: '#EF476F',
  },
};

export default extendedColors;
