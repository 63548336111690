import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  ReactNode,
  useCallback,
} from 'react';
import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Text,
} from '@chakra-ui/react';
import Close from 'components/Icons/Close';

type FormDrawer = {
  title: string;
  content: JSX.Element | null;
};

type FormDrawerContextType = {
  setFormDrawer: React.Dispatch<React.SetStateAction<FormDrawer | null>>;
  formDrawer: FormDrawer | null;
  closeFormDrawer: () => void;
};

const FormDrawerContextDefaultState: FormDrawerContextType = {
  setFormDrawer: (): void => {},
  formDrawer: { title: '', content: null },
  closeFormDrawer: (): void => {},
};

const FormDrawerContext = createContext(FormDrawerContextDefaultState);

function FormDrawerContextProvider({ children }: { children: ReactNode }) {
  const [drawer, setFormDrawer] = useState<FormDrawer | null>(null);

  const closeFormDrawer = useCallback(() => {
    setFormDrawer(null);
  }, []);

  const globalContextValue = useMemo(
    () => ({
      ...FormDrawerContextDefaultState,
      setFormDrawer,
      closeFormDrawer,
    }),
    [closeFormDrawer],
  );

  return (
    <FormDrawerContext.Provider value={globalContextValue}>
      <Drawer
        onClose={closeFormDrawer}
        isOpen={!!drawer}
        placement="right"
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <HStack spacing={2} justify="space-between">
              <Text variant="h1">{drawer?.title}</Text>
              <DrawerCloseButton>
                <Close />
              </DrawerCloseButton>
            </HStack>
          </DrawerHeader>
          {drawer?.content}
        </DrawerContent>
      </Drawer>
      {children}
    </FormDrawerContext.Provider>
  );
}

const useFormDrawer = () => useContext(FormDrawerContext);

export { FormDrawerContextProvider, useFormDrawer };
