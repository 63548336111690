import { useIsMutating } from 'react-query';
import { Link } from 'react-router-dom';
import MainLayout from 'components/Layout/MainLayout';
import Table from 'pages/Coupons/Listing/components/Table';
import LoadingIndicator from 'components/LoadingIndicator';
import { useGetCoupons } from 'api/coupons';
import Add from 'components/Icons/Add';
import Import from 'components/Icons/Import';
import useQueryParams from 'utils/useQueryParams';
import Footer from 'components/Footer';
import { useFormDrawer } from 'context/FormDrawerContext';
import CouponsImport from 'pages/Coupons/Form/Import';
import ROUTES from 'app/routes';
import FilterBody from 'pages/Coupons/Listing/components/FilterBody';

function Coupons() {
  const { queryString } = useQueryParams({
    limit: '10',
    page: '1',
  });

  const { setFormDrawer } = useFormDrawer();

  const isMutating = useIsMutating({
    mutationKey: ['couponsMutation'],
  });

  const {
    isFetching,
    data: { data: coupons, totals } = { data: [], totals: 0 },
  } = useGetCoupons(queryString);

  const showLoading = isMutating || isFetching;

  return (
    <MainLayout
      title="Kupony rabatowe"
      headerButtons={[
        {
          label: 'Dodaj',
          leftIcon: <Add />,
          as: Link,
          to: ROUTES.coupons.create,
        },
        {
          label: 'Importuj',
          leftIcon: <Import />,
          onClick: () =>
            setFormDrawer({
              title: 'Importuj kupony rabatowe',
              content: <CouponsImport />,
            }),
        },
      ]}
      filterBody={<FilterBody />}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && <Table data={coupons} />}
      <Footer totals={totals} />
    </MainLayout>
  );
}

export default Coupons;
