import { Box, Stack } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { kebabCase } from 'lodash';
import LoadingSpinner from 'components/LoadingSpinner';
import { FormBodyProps } from 'types/common';
import Input from 'components/Form/Input';
import Switch from 'components/Form/Switch';
import { DEFAULT_POOL } from 'pages/CouponPools/Form/constants';

interface CoupoPoolsFormBody extends FormBodyProps {
  poolLabel?: string;
}

function FormBody({ isLoading, poolLabel }: CoupoPoolsFormBody) {
  const { control, setValue } = useFormContext();
  const label = useWatch({
    control,
    name: 'label',
  });

  useEffect(() => {
    setValue('qrCode', kebabCase(label));
  }, [label, setValue]);

  return (
    <Box w="100%">
      {isLoading && <LoadingSpinner />}
      <Stack spacing={4}>
        {poolLabel !== DEFAULT_POOL && (
          <>
            <Input name="label" label="Nazwa" placeholder="Wpisz nazwę" />
            <Input
              name="qrCode"
              label="Kod QR"
              placeholder="Wpisz nazwę, aby wygenerować kod"
              isReadOnly
            />
            <Input
              name="defaultCouponValue"
              label="Domyślna wartość kuponu"
              placeholder="Wpisz wartość"
              type="number"
            />
          </>
        )}
        <Switch
          name="alertIfTooFewCoupons"
          label="Powiadomienie, jeśli zostało mało kuponów"
        />
      </Stack>
    </Box>
  );
}

FormBody.defaultProps = {
  poolLabel: '',
};

export default FormBody;
