import styled from '@emotion/styled';

const Td = styled.td`
  :nth-of-type(1) {
    width: 240px;
  }
  :nth-of-type(2) {
    width: 360px;
  }
  :nth-of-type(3) {
    width: 120px;
  }
  :nth-of-type(4) {
    width: 180px;
  }
  :nth-of-type(5) {
    width: 180px;
  }
  :nth-of-type(6) {
    width: 180px;
  }
  :nth-of-type(7) {
    width: 32px;
  }
`;

export default Td;
