import * as yup from 'yup';
import { ERROR_MESSAGES } from 'utils/form';

const BODY_SCHEMA = yup.object({
  coupons: yup
    .array()
    .of(
      yup.object().shape({
        code: yup.string().required(ERROR_MESSAGES.required),
        description: yup.string(),
        detailedDescription: yup.string(),
        validTo: yup.date().nullable().required(ERROR_MESSAGES.required),
        value: yup
          .number()
          .typeError(ERROR_MESSAGES.invalidNumberType)
          .required(ERROR_MESSAGES.required),
      }),
    )
    .required(),
});

const DEFAULT_VALUES = {
  coupons: [
    {
      code: '',
      description: '',
      detailedDescription: '',
      validTo: undefined,
      value: 0,
    },
  ],
};

export { BODY_SCHEMA, DEFAULT_VALUES };
