import * as yup from 'yup';
import { ERROR_MESSAGES } from 'utils/form';

const BODY_SCHEMA = yup.object({
  label: yup.string().required(ERROR_MESSAGES.required),
  qrCode: yup.string().required(ERROR_MESSAGES.required),
  defaultCouponValue: yup
    .number()
    .typeError(ERROR_MESSAGES.invalidNumberType)
    .required(ERROR_MESSAGES.required),
  alertIfTooFewCoupons: yup.boolean(),
});

const DEFAULT_VALUES = {
  label: '',
  qrCode: '',
  defaultCouponValue: 10,
  alertIfTooFewCoupons: false,
};

const DEFAULT_POOL = 'default';

type FormData = yup.InferType<typeof BODY_SCHEMA>;

export { BODY_SCHEMA, DEFAULT_VALUES, DEFAULT_POOL };
export type { FormData };
