import { toast } from 'react-toastify';
import Success from 'components/Icons/Success';
import Error from 'components/Icons/Error';

const notify = (type: 'success' | 'error', message: string) => {
  if (type === 'error') {
    return toast.error(message, { icon: Error });
  }

  return toast.success(message, { icon: Success });
};

export default notify;
