import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';
import get from 'lodash/get';
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import pl from 'date-fns/locale/pl';
import styled from '@emotion/styled';
import Input from 'components/Form/Date/input';

registerLocale('pl', pl);

const Popper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
`;

const Wrapper = styled(FormControl)`
  position: relative;
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__close-icon {
      position: absolute;
      margin-right: 48px;

      &:after {
        background-color: #ebedfb;
        color: #0b24fb;
        font-size: 14px;
        width: 12px;
        height: 12px;
        display: block;
        line-height: 10px;
      }
    }
  }
`;

type DateProps = Omit<ReactDatePickerProps, 'onChange'> & {
  label?: string;
  name: string;
  showError?: boolean;
  isRequired?: boolean;
  hidden?: boolean;
  mb?: number | string;
  rangeSelect?: boolean;
  isClearable?: boolean;
};

function Date({
  label,
  name,
  showError,
  isRequired,
  hidden,
  mb,
  rangeSelect,
  isClearable,
  ...props
}: DateProps) {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const errorMessage = get(errors, name)?.message as string;

  return (
    <Wrapper
      hidden={hidden}
      isInvalid={Boolean(errorMessage)}
      isRequired={isRequired}
      mb={mb}
    >
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange: _onChange, onBlur, value } }) => {
          if (rangeSelect) {
            const { start, end } = value || {};
            return (
              <ReactDatePicker
                {...props}
                popperContainer={Popper}
                portalId="root-portal"
                startDate={start || null}
                endDate={end || null}
                onChange={(e) => {
                  const val = { start: e[0], end: e[1] };
                  _onChange(val);
                }}
                onBlur={onBlur}
                customInput={React.createElement(React.forwardRef(Input))}
                selectsRange
                dateFormat="dd.MM.yyyy"
                locale="pl"
                strictParsing
                isClearable={isClearable}
                showPopperArrow={false}
              />
            );
          }

          return (
            <ReactDatePicker
              {...props}
              popperContainer={Popper}
              portalId="root-portal"
              selected={value}
              onChange={_onChange}
              onBlur={onBlur}
              customInput={React.createElement(React.forwardRef(Input))}
              dateFormat="dd.MM.yyyy"
              locale="pl"
              strictParsing
              isClearable={isClearable}
              showPopperArrow={false}
            />
          );
        }}
      />
      {showError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </Wrapper>
  );
}

Date.defaultProps = {
  label: '',
  hidden: false,
  isRequired: false,
  showError: false,
  mb: 0,
  rangeSelect: false,
  isClearable: true,
};

export default Date;
