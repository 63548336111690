import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useMemo } from 'react';
import { datePrettierNoTime } from 'utils/date';
import Card from 'components/Card';
import colors from 'theme/colors';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
);

const OPTIONS = {
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    y: {
      beginAtZero: true,
    },
  },
  ticks: {
    precision: 0,
  },
};

interface ChartProps {
  detailedReport: Report[];
}

function Chart({ detailedReport }: ChartProps) {
  const data = useMemo(
    () => ({
      labels: detailedReport?.map((report) => datePrettierNoTime(report?.date)),
      datasets: [
        {
          label: 'Suma przypisanych kuponów',
          data: detailedReport?.map((report) => report?.assigned),
          borderColor: colors.complementary.primary,
          backgroundColor: colors.complementary.lightBlue,
        },
      ],
    }),
    [detailedReport],
  );

  return (
    <Card height="600px">
      <Line options={OPTIONS} data={data} />
    </Card>
  );
}
export default Chart;
