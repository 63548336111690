import { ColumnDef } from '@tanstack/react-table';
import QrCodePreviewModal from 'components/QrCodePreviewModal';
import { datePrettier } from 'utils/date';

function GetColumns(): ColumnDef<CouponPool, string>[] {
  return [
    {
      header: 'Nazwa',
      accessorKey: 'label',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Kod QR',
      cell: ({
        row: {
          original: { label, qrCode },
        },
      }) => <QrCodePreviewModal label={label} qrCodeValue={qrCode} />,
    },
    {
      header: 'Data utworzenia',
      accessorKey: 'createdAt',
      cell: (info) => datePrettier(info.getValue()),
    },
    {
      header: 'Domyślna wartość kuponu',
      accessorKey: 'defaultCouponValue',
      cell: (info) => info.getValue(),
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
