const Menu = {
  parts: ['item', 'list'],
  baseStyle: {
    item: {
      p: '8px 16px',
      _hover: {
        bg: 'complementary.lightBlue',
      },
      _focus: {
        bg: 'complementary.lightBlue',
      },
    },
    list: {
      minW: '160px',
    },
  },
};

export default Menu;
