/* eslint-disable @typescript-eslint/dot-notation */
import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const setAuthHeader = (token: string) => {
  API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const setStoreHeader = (store: string) => {
  API.defaults.headers.common['Store'] = store;
};

const destroySession: () => void = () => {
  delete API.defaults.headers.common['Authorization'];
};

const APIRoutes = {
  auth: {
    signIn: '/auth/sign_in',
  },
  coupons: {
    index: (queryString?: string) => `/coupons${queryString ?? ''}`,
    batch: '/coupons/batch',
    pools: '/coupons/pools/list',
    import: '/coupons/batch/csv',
    statistics: ({ couponPoolId, date, value }: CouponPoolStatisticsParams) =>
      `/coupons/report/${couponPoolId}?detailedFromDate=${date}${
        value ? `&value=${value}` : ''
      }`,
  },
  users: {
    index: (queryString?: string) => `/users${queryString ?? ''}`,
    byId: (id: string) => `/users/${id}`,
  },
  qrCodes: {
    generator: (value: string) => `/qr/${value}.png`,
  },
  couponPools: {
    index: (queryString?: string) => `/coupon_pools${queryString ?? ''}`,
    byId: (id: string) => `/coupon_pools/${id}`,
  },
};

const DEFAULT_STALE_TIME = 10 * 1000;
const EXTENDED_STALE_TIME = 60 * 1000;

export {
  API,
  APIRoutes,
  setAuthHeader,
  setStoreHeader,
  destroySession,
  DEFAULT_STALE_TIME,
  EXTENDED_STALE_TIME,
};
