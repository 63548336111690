import { Icon, IconProps } from '@chakra-ui/react';

function CouponPools(props: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_326_2141"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_326_2141)">
        <path
          d="M5.42307 20.1923L3.58655 9.19233H20.4134L18.5769 20.1923H5.42307ZM6.67497 18.6923H17.325L18.6 10.6923H5.39997L6.67497 18.6923ZM9.99997 14.4423H14C14.2125 14.4423 14.3906 14.3704 14.5343 14.2266C14.6781 14.0827 14.7499 13.9045 14.7499 13.692C14.7499 13.4794 14.6781 13.3013 14.5343 13.1577C14.3906 13.0141 14.2125 12.9423 14 12.9423H9.99997C9.78747 12.9423 9.60936 13.0142 9.46562 13.158C9.32187 13.3018 9.25 13.48 9.25 13.6926C9.25 13.9052 9.32187 14.0833 9.46562 14.2269C9.60936 14.3705 9.78747 14.4423 9.99997 14.4423ZM5.99997 8C5.78747 8 5.60936 7.9281 5.46562 7.78428C5.32187 7.64048 5.25 7.46228 5.25 7.24968C5.25 7.0371 5.32187 6.85901 5.46562 6.71543C5.60936 6.57183 5.78747 6.50003 5.99997 6.50003H18C18.2125 6.50003 18.3906 6.57194 18.5343 6.71575C18.6781 6.85957 18.7499 7.03777 18.7499 7.25035C18.7499 7.46295 18.6781 7.64105 18.5343 7.78463C18.3906 7.92821 18.2125 8 18 8H5.99997ZM7.99997 5.30773C7.78747 5.30773 7.60936 5.23582 7.46562 5.092C7.32187 4.94819 7.25 4.76999 7.25 4.55741C7.25 4.34481 7.32187 4.16671 7.46562 4.02313C7.60936 3.87955 7.78747 3.80775 7.99997 3.80775H16C16.2125 3.80775 16.3906 3.87966 16.5343 4.02348C16.6781 4.16728 16.7499 4.34548 16.7499 4.55808C16.7499 4.77066 16.6781 4.94875 16.5343 5.09233C16.3906 5.23593 16.2125 5.30773 16 5.30773H7.99997Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}

export default CouponPools;
