const LIMITS = {
  minPasswordLength: 8,
};

const ERROR_MESSAGES = {
  required: 'Pole jest wymagane',
  invalidEmail: 'Niewłaściwy adres e-mail',
  minPasswordLength: `Hasło musi zawierać przynajmniej ${LIMITS.minPasswordLength} znaków`,
  invalidNumberType: 'Wprowadź liczbę',
};

export { LIMITS, ERROR_MESSAGES };
