import { Icon, IconProps } from '@chakra-ui/react';

function ChevronsRight(props: IconProps) {
  return (
    <Icon
      width="25px"
      height="24px"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_28_3338"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="24"
      >
        <rect x="0.549438" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_28_3338)">
        <path
          d="M7.54941 18L6.14941 16.6L10.7494 12L6.14941 7.4L7.54941 6L13.5494 12L7.54941 18ZM16.5494 18V6H18.5494V18H16.5494Z"
          fill="black"
        />
      </g>
    </Icon>
  );
}

export default ChevronsRight;
