import { InferType } from 'yup';
import { DrawerBody, DrawerFooter, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import FormBody from 'pages/Users/Form/components/FormBody';
import { CREATE_SCHEMA, USER_ROLES } from 'pages/Users/Form/constants';
import { API, APIRoutes } from 'api';
import { useFormWithSchema } from 'utils/formHooks';
import FormButtons from 'components/FormButtons';
import { useFormDrawer } from 'context/FormDrawerContext';
import notify from 'utils/notify';
import transformErrors from 'utils/api';

type FormData = InferType<typeof CREATE_SCHEMA>;

function UserCreate() {
  const queryClient = useQueryClient();
  const { closeFormDrawer } = useFormDrawer();

  const methods = useFormWithSchema(CREATE_SCHEMA, {
    mode: 'onChange',
    defaultValues: {
      role: USER_ROLES.ADMIN,
    },
  });

  const {
    handleSubmit,
    formState: { isValid },
    setError,
  } = methods;

  const { mutate: createUser, isLoading } = useMutation({
    mutationKey: 'usersMutation',
    mutationFn: (data: FormData) => API.post(APIRoutes.users.index(), data),
    onSuccess: () => {
      closeFormDrawer();
      notify('success', 'Użytkownik został pomyślnie utworzony!');
      queryClient.invalidateQueries('users');
    },
    onError: ({ errors }) => {
      const transformedErrors = transformErrors(errors);
      Object.keys(transformedErrors).forEach((field: string) => {
        setError(field as never, {
          type: 'custom',
          message: transformedErrors[field],
        });
      });
      notify('error', 'Wystąpił problem podczas tworzenia użytkownika');
    },
  });

  const onSubmit = handleSubmit(async (data: FormData) => createUser(data));

  return (
    <>
      <DrawerBody>
        <FormProvider {...methods}>
          <VStack
            w="100%"
            h="100%"
            as="form"
            id="user-creator"
            onSubmit={onSubmit}
            justify="space-between"
          >
            <FormBody isLoading={isLoading} />
          </VStack>
        </FormProvider>
      </DrawerBody>
      <DrawerFooter>
        <FormButtons
          onCancel={closeFormDrawer}
          isLoading={isLoading}
          isDisabled={!isValid}
          formId="user-creator"
        />
      </DrawerFooter>
    </>
  );
}

export default UserCreate;
