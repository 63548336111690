import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  HStack,
  IconButton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import Delete from 'components/Icons/Delete';
import Card from 'components/Card';
import LoadingSpinner from 'components/LoadingSpinner';
import FormBody from 'pages/Coupons/Form/Create/components/FormBody';
import { DEFAULT_VALUES } from 'pages/Coupons/Form/Create/constants';

interface ContainerWrapperProps {
  isLoading: boolean;
}

function ContainerWrapper({ isLoading }: ContainerWrapperProps) {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'coupons',
    keyName: 'fieldId',
  });

  return (
    <Accordion allowMultiple defaultIndex={[0]} w="100%">
      <Stack w="100%" spacing={4}>
        {fields.map((field, fieldIndex) => (
          <Card as={Stack} spacing={4} key={field.fieldId}>
            {isLoading && <LoadingSpinner />}
            <AccordionItem>
              <AccordionButton as={Box}>
                <HStack w="100%" spacing={2}>
                  <Text variant="h4" fontWeight={600} w="100%" align="left">
                    {fieldIndex + 1} kupon rabatowy
                  </Text>
                  <IconButton
                    variant="unstyled"
                    icon={<AccordionIcon fontSize="24px" />}
                    aria-label="toggle"
                    minWidth="24px"
                    height="24px"
                  />
                  <IconButton
                    variant="unstyled"
                    icon={<Delete />}
                    onClick={() => remove(fieldIndex)}
                    aria-label="remove"
                    minWidth="24px"
                    height="24px"
                  />
                </HStack>
              </AccordionButton>
              <AccordionPanel>
                <FormBody index={fieldIndex} />
              </AccordionPanel>
            </AccordionItem>
          </Card>
        ))}
        <Card>
          <Button
            variant="outlined"
            w="100%"
            onClick={() => append(DEFAULT_VALUES.coupons[0])}
          >
            Dodaj
          </Button>
        </Card>
      </Stack>
    </Accordion>
  );
}

export default ContainerWrapper;
