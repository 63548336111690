import { Stack } from '@chakra-ui/react';
import { useCallback } from 'react';
import Input from 'components/Form/Input';
import DatePicker from 'components/Form/Date';
import CouponPoolSelect from 'components/CouponPoolSelect';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';

export interface FormBodyProps {
  index: number;
}

function FormBody({ index }: FormBodyProps) {
  const fieldName = useCallback(
    (name: string) => `coupons[${index}].${name}`,
    [index],
  );

  return (
    <Stack w="100%" spacing={4} maxW={FORM_BODY_MAX_WIDTH}>
      <Input
        name={fieldName('code')}
        label="Kod kuponu"
        placeholder="Wpisz kod"
      />
      <Input
        name={fieldName('description')}
        label="Opis"
        placeholder="Wpisz opis"
      />
      <Input
        name={fieldName('detailedDescription')}
        label="Szczegółowy opis"
        placeholder="Wpisz opis"
      />
      <DatePicker name={fieldName('validTo')} label="Ważny do" showError />
      <Input
        name={fieldName('value')}
        label="Wartość"
        placeholder="Wpisz wartość"
        type="number"
      />
      <CouponPoolSelect name={fieldName('couponPool')} showLabel showError />
    </Stack>
  );
}

export default FormBody;
