import { Box, Center, Flex, Text } from '@chakra-ui/react';

interface PlaceholderProps {
  icon: React.ReactNode;
  title: string;
  subtitle?: string;
}

function Placeholder({ icon, title, subtitle }: PlaceholderProps) {
  return (
    <Center
      h="100%"
      bgColor="complementary.lightBlue"
      padding="24px 48px"
      borderRadius="6px"
    >
      <Flex direction="column" align="center">
        <Box
          backgroundColor="complementary.white"
          padding={2}
          borderRadius="50%"
          mb={2}
        >
          {icon}
        </Box>
        <Text mb={1}>{title}</Text>
        {subtitle && (
          <Text color="complementary.placeholder" fontSize="14px">
            {subtitle}
          </Text>
        )}
      </Flex>
    </Center>
  );
}

Placeholder.defaultProps = {
  subtitle: '',
};
export default Placeholder;
