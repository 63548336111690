import {
  AccordionButton,
  AccordionIcon,
  AccordionItem as ChakraAccordionItem,
  AccordionPanel,
  Box,
  HStack,
} from '@chakra-ui/react';
import find from 'lodash/find';
import { useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Option } from 'types/navigation';
import useQueryParams from 'utils/useQueryParams';
import colors from 'theme/colors';

interface ExpandableNavItemProps {
  icon: React.ReactNode;
  title: string;
  options: Option[];
}

function ExpandableNavItem({ icon, title, options }: ExpandableNavItemProps) {
  const { queryString } = useQueryParams();
  const { pathname } = useLocation();
  const isSubItemActive = useMemo(
    () => !!find(options, (opt) => pathname.includes(opt.link)),
    [pathname, options],
  );

  return (
    <ChakraAccordionItem position="relative" border="none">
      <AccordionButton
        fontSize="16px"
        padding={4}
        {...(isSubItemActive && {
          background: `${colors.complementary.hoverBlue}73`,
        })}
        _hover={{
          background: `${colors.complementary.hoverBlue}4D`,
        }}
      >
        <HStack spacing={2} w="100%">
          {icon}
          <Box>{title}</Box>
        </HStack>
        <AccordionIcon />
      </AccordionButton>

      <AccordionPanel p={0}>
        {options.map((option) => {
          const link = option.link === pathname ? queryString : option.link;

          return (
            <NavLink to={link} key={`expanded_option_${option.title}`}>
              {({ isActive }) => (
                <AccordionButton
                  padding="12px 32px"
                  fontWeight={isActive ? 600 : 400}
                  _hover={{
                    background: `${colors.complementary.hoverBlue}4D`,
                  }}
                >
                  {option.title}
                </AccordionButton>
              )}
            </NavLink>
          );
        })}
      </AccordionPanel>
    </ChakraAccordionItem>
  );
}

export default ExpandableNavItem;
