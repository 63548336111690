import * as yup from 'yup';

const DATE = 'date';
const COUPON_POOL_ID = 'couponPoolId';
const VALUE = 'value';

const schema = yup.object({
  date: yup.date().nullable().required(),
  couponPoolId: yup.string().required(),
  value: yup.string().nullable(),
});

const VALUE_OPTIONS = ['20', '50', '100'].map((value) => ({
  label: value,
  value,
}));

export { DATE, COUPON_POOL_ID, VALUE, schema, VALUE_OPTIONS };
