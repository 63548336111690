const Textarea = {
  baseStyle: {
    border: 'none',
  },
  variants: {
    outline: {
      borderColor: 'complementary.outline',
      padding: '14px 16px',
      backgroundColor: 'complementary.white',
      fontSize: '14px',
      lineHeight: '20px',
      color: 'complementary.black',
      fontWeight: 'normal',
      borderWidth: '1px',
      borderRadius: '8px',
      _placeholder: { color: 'complementary.placeholder' },
      _invalid: {
        boxShadow: 'unset',
        borderColor: 'complementary.error',
      },
      _disabled: {
        opacity: 'unset',
        backgroundColor: 'complementary.superLightPurple',
        color: 'complementary.outline',
        _placeholder: { color: 'complementary.outline' },
      },
      _focusVisible: {
        outline: 'none',
        boxShadow: 'unset',
        borderColor: 'complementary.primary',
      },
    },
  },
  defaultProps: {
    variant: 'outline',
    size: 'lg',
  },
};

export default Textarea;
