const Accordion = {
  parts: ['container', 'panel', 'button'],
  baseStyle: {
    container: {
      border: 'none',
    },
    panel: {
      padding: '16px 0px',
    },
    button: {
      padding: '16px 0px',
      _hover: {
        backgroundColor: 'transparent',
      },
    },
  },
};

export default Accordion;
