export const ERROR_DICT: { [key: string]: string } = {
  unique: 'Wartość musi być unikalna',
};

const transformErrors: (errors: { [key: string]: { kind: string } }) => {
  [key: string]: string;
} = (errors) => {
  try {
    if (errors) {
      const err: { [key: string]: string } = {};

      Object.keys(errors).forEach((field: string) => {
        const errorKind = errors[field].kind;
        err[field] = ERROR_DICT[errorKind];
      });

      return err;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }

  return {};
};

export default transformErrors;
