import { format, parse } from 'date-fns';
import isNil from 'lodash/isNil';

const datePrettier = (dateString: string | undefined): string => {
  if (isNil(dateString)) return '';
  const dt = new Date(dateString);
  const date = format(dt, 'dd.MM.yyyy');
  const time = format(dt, 'HH:mm');
  return `${date} ${time}`;
};

const datePrettierNoTime = (dateString: string | undefined): string => {
  if (isNil(dateString)) return '';
  const dt = new Date(dateString);
  const date = format(dt, 'dd.MM.yyyy');
  return date;
};

const dateFormatNoTime = (date: Date): string => {
  if (isNil(date)) return '';
  const dt = format(new Date(date), 'MM-dd-yyyy');
  return dt;
};

const dateParseNoTime = (dateString: string | null): Date | undefined => {
  if (isNil(dateString)) return undefined;
  return parse(dateString, 'MM-dd-yyyy', new Date());
};

export { datePrettier, datePrettierNoTime, dateFormatNoTime, dateParseNoTime };
