import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import QrCodePreview from 'components/QrCodePreview';
import { DEFAULT_POOL } from 'pages/CouponPools/Form/constants';

interface QrCodePreviewModalProps {
  label: string;
  qrCodeValue: string;
}

function QrCodePreviewModal({ label, qrCodeValue }: QrCodePreviewModalProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  if (!qrCodeValue || label === DEFAULT_POOL) {
    return <Text>Brak</Text>;
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <ModalHeader>
              <Text variant="h4" fontWeight={500}>
                {label}
              </Text>
            </ModalHeader>
            <QrCodePreview qrCodeValue={qrCodeValue} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Text cursor="pointer" textDecoration="underline" onClick={onOpen}>
        Podgląd
      </Text>
    </>
  );
}

export default QrCodePreviewModal;
