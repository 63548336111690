import { InferType } from 'yup';
import { DrawerBody, DrawerFooter, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { pickBy } from 'lodash';
import { useEffect } from 'react';
import FormBody from 'pages/Users/Form/components/FormBody';
import { EDIT_SCHEMA } from 'pages/Users/Form/constants';
import { API, APIRoutes } from 'api';
import FormButtons from 'components/FormButtons';
import { useFormWithSchema } from 'utils/formHooks';
import { useFormDrawer } from 'context/FormDrawerContext';
import notify from 'utils/notify';
import { useGetUser } from 'api/users';

type FormData = InferType<typeof EDIT_SCHEMA>;

function UserEdit({ id }: { id: string }) {
  const queryClient = useQueryClient();
  const { closeFormDrawer } = useFormDrawer();

  const { data: { data: user } = {}, isFetching } = useGetUser(id);

  const methods = useFormWithSchema(EDIT_SCHEMA, {
    mode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { isValid },
    reset,
  } = methods;

  const { mutate: editUser, isLoading: isEditing } = useMutation({
    mutationKey: 'usersMutation',
    mutationFn: (data: FormData) =>
      API.patch(APIRoutes.users.byId(id), pickBy(data)),
    onSuccess: () => {
      closeFormDrawer();
      notify('success', 'Zmiany w użytkowniku zostały zastosowane');
      queryClient.invalidateQueries('users');
    },
    onError: () => {
      notify('error', 'Wystąpił problem podczas edycji użytkownika');
    },
  });

  const onSubmit = handleSubmit(async (data: FormData) => editUser(data));

  useEffect(() => {
    if (user) reset({ ...user });
  }, [user, reset]);

  const isLoading = isFetching || isEditing;

  return (
    <>
      <DrawerBody>
        <FormProvider {...methods}>
          <VStack
            w="100%"
            h="100%"
            as="form"
            id="user-editor"
            onSubmit={onSubmit}
            justify="space-between"
          >
            <FormBody isLoading={isLoading} isEditMode />
          </VStack>
        </FormProvider>
      </DrawerBody>
      <DrawerFooter>
        <FormButtons
          onCancel={closeFormDrawer}
          isLoading={isLoading}
          isDisabled={!isValid}
          formId="user-editor"
        />
      </DrawerFooter>
    </>
  );
}

export default UserEdit;
