import { Image, Text } from '@chakra-ui/react';
import { useMemo } from 'react';
import { APIRoutes } from 'api';

function QrCodePreview({ qrCodeValue }: { qrCodeValue: string }) {
  const qrCodeSrc = useMemo(
    () =>
      `${process.env.REACT_APP_API_URL}${APIRoutes.qrCodes.generator(
        qrCodeValue,
      )}`,
    [qrCodeValue],
  );

  return (
    <Image
      src={qrCodeSrc}
      alt="qr-code-preview"
      fallbackStrategy="onError"
      fallback={
        <Text variant="h2">Wystąpił błąd podczas generowania kodu QR</Text>
      }
    />
  );
}

export default QrCodePreview;
