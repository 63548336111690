const FormLabel = {
  baseStyle: {
    fontSize: '14px',
    lineHeight: '20px',
    marginRight: 0,
    marginBottom: '8px',
  },
};

export default FormLabel;
