import { IconButton } from '@chakra-ui/react';
import { ToastContentProps } from 'react-toastify';
import Close from 'components/Icons/Close';

function CloseButton({ closeToast }: Partial<ToastContentProps>) {
  return (
    <IconButton onClick={closeToast} aria-label="close" variant="unstyled">
      <Close />
    </IconButton>
  );
}
export default CloseButton;
