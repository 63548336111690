import { Icon, IconProps } from '@chakra-ui/react';

function Add(props: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_15_1263"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_15_1263)">
        <path
          d="M11 13H5V11H11V5H13V11H19V13H13V19H11V13Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}

export default Add;
