import { useEffect, useState } from 'react';
import { useAuth } from 'context/AuthContext';
import { API } from 'api';

function AxiosInterceptor({ children }: { children: JSX.Element }) {
  const { logout } = useAuth();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const responseInterceptor = API.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          return logout();
        }
        return Promise.reject(error.response.data);
      },
    );

    setIsInitialized(true);

    return () => {
      API.interceptors.response.eject(responseInterceptor);
      setIsInitialized(false);
    };
  }, [logout]);

  return isInitialized ? children : null;
}

export default AxiosInterceptor;
