const POOL = 'couponPoolId';
const VALUE = 'value';
const ONLY_VALID = 'onlyValid';
const ONLY_AVAILABLE = 'onlyAvailable';
const ARCHIVED = 'archived';
const PAGE = 'page';

const VALUE_OPTIONS = ['20', '50', '100'].map((value) => ({
  label: value,
  value,
}));

export {
  POOL,
  VALUE,
  ONLY_VALID,
  ONLY_AVAILABLE,
  ARCHIVED,
  PAGE,
  VALUE_OPTIONS,
};
