import { useCallback } from 'react';
import { flexRender, Row } from '@tanstack/react-table';
import { IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import { useQueryClient, useMutation } from 'react-query';
import Td from 'pages/CouponPools/Listing/components/Table/components/Td';
import Edit from 'components/Icons/Edit';
import Delete from 'components/Icons/Delete';
import Tr from 'components/Table/Tr';
import { API, APIRoutes } from 'api';
import CustomModal from 'components/CustomModal';
import { useFormDrawer } from 'context/FormDrawerContext';
import CouponPoolEdit from 'pages/CouponPools/Form/Edit';
import notify from 'utils/notify';

const isProduction = process.env.REACT_APP_ENV === 'production';

interface TableRowProps {
  row: Row<CouponPool>;
}

function TableRow({ row }: TableRowProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const queryClient = useQueryClient();
  const { setFormDrawer } = useFormDrawer();
  const { _id } = row.original;

  const { mutate: removeCouponPool } = useMutation(
    () => API.delete(APIRoutes.couponPools.byId(_id!)),
    {
      mutationKey: 'couponPoolsMutation',
      onSuccess: () => {
        notify('success', 'Pomyślnie usunięto pulę kuponów');
        onClose();
        queryClient.invalidateQueries('couponPools');
      },
      onError: () => {
        notify('error', 'Wystąpił problem podczas usuwania puli kuponów');
      },
    },
  );

  const openEditor = useCallback(() => {
    setFormDrawer({
      title: 'Edytuj',
      content: <CouponPoolEdit id={_id!} />,
    });
  }, [_id, setFormDrawer]);

  return (
    <Tr key={`row_${_id}`}>
      {row.getVisibleCells().map((cell, idx, { length }) => {
        if (idx + 1 === length) return null;
        return (
          <Td key={`row_cell_${cell.id}_${cell}`}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        );
      })}
      <Td>
        <>
          <CustomModal
            confirmationText="Usuń"
            onAccept={removeCouponPool}
            cancellationText="Anuluj"
            onClose={onClose}
            isOpen={isOpen}
            title="Czy na pewno chcesz trwale usunąć tę pulę kuponów?"
          />
          <Tooltip label="Edytuj" openDelay={500}>
            <IconButton
              aria-label="edit"
              icon={<Edit />}
              width="32px"
              height="32px"
              variant="ghost"
              onClick={openEditor}
            />
          </Tooltip>
          {!isProduction && (
            <Tooltip label="Usuń" openDelay={500}>
              <IconButton
                aria-label="delete"
                icon={<Delete />}
                width="32px"
                height="32px"
                variant="ghost"
                onClick={onOpen}
              />
            </Tooltip>
          )}
        </>
      </Td>
    </Tr>
  );
}

export default TableRow;
