import { Text, HStack, StackDivider } from '@chakra-ui/react';
import Info from 'components/Icons/Info';

function StatBox({ total, available, assigned }: CouponCount) {
  return (
    <HStack
      w="100%"
      bgColor="complementary.lightBlue"
      padding="8px 32px 8px 16px"
      borderRadius="8px"
      spacing={2}
    >
      <Info />
      <HStack
        spacing={2}
        divider={<StackDivider borderColor="complementary.richBlack" />}
        fontSize="14px"
      >
        <Text>
          Suma wszystkich kuponów:
          <Text as="span" fontWeight={600} ml={2}>
            {total}
          </Text>
        </Text>
        <Text>
          Suma dostępnych kuponów:
          <Text as="span" fontWeight={600} ml={2}>
            {available}
          </Text>
        </Text>
        <Text>
          Suma przypisanych kuponów:
          <Text as="span" fontWeight={600} ml={2}>
            {assigned}
          </Text>
        </Text>
      </HStack>
    </HStack>
  );
}
export default StatBox;
