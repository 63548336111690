import { useQuery } from 'react-query';
import isEmpty from 'lodash/isEmpty';
import { API, APIRoutes, DEFAULT_STALE_TIME, EXTENDED_STALE_TIME } from 'api';

const fetchCoupons = async (queryString: string) => {
  const { data: response } = await API.get(
    APIRoutes.coupons.index(queryString),
  );
  return {
    data: response?.data,
    totals: response?.totalCount,
  };
};

const fetchCouponPoolStatistics = async (
  params: CouponPoolStatisticsParams,
) => {
  const { data: response } = await API.get(
    APIRoutes.coupons.statistics(params),
  );
  return response;
};

const useGetCoupons = (queryString: string) =>
  useQuery<CouponsResponse>(
    ['coupons', queryString],
    () => fetchCoupons(queryString),
    {
      staleTime: DEFAULT_STALE_TIME,
    },
  );

const useGetCouponPoolStatistics = (params: CouponPoolStatisticsParams) =>
  useQuery<CouponStatisticsResponse>(
    ['couponPoolStatistics', params],
    () => fetchCouponPoolStatistics(params),
    {
      staleTime: EXTENDED_STALE_TIME,
      enabled: !isEmpty(params),
    },
  );

export { useGetCoupons, useGetCouponPoolStatistics };
