import { ColumnDef } from '@tanstack/react-table';
import { datePrettier } from 'utils/date';

function GetColumns(): ColumnDef<Coupon, string>[] {
  return [
    {
      header: 'Kod',
      accessorKey: 'code',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Opis',
      accessorKey: 'description',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Wartość',
      accessorKey: 'value',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Pula',
      accessorKey: 'couponPool.label',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Ważny do',
      accessorKey: 'validTo',
      cell: (info) => datePrettier(info.getValue()),
    },
    {
      header: 'Wykorzystany',
      accessorKey: 'usedAt',
      cell: (info) => (info.getValue() ? datePrettier(info.getValue()) : '-'),
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
