import { useMemo } from 'react';
import qs from 'query-string';
import { Stack } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { useGetCouponPoolStatistics } from 'api/coupons';
import MainLayout from 'components/Layout/MainLayout';
import LoadingIndicator from 'components/LoadingIndicator';
import Chart from 'pages/Coupons/Statistics/components/Chart';
import Search from 'pages/Coupons/Statistics/components/Search';
import useQueryParams from 'utils/useQueryParams';
import StatBox from 'pages/Coupons/Statistics/components/StatBox';

function Statistics() {
  const { queryString } = useQueryParams();

  const params = useMemo(
    () => qs.parse(queryString) as CouponPoolStatisticsParams,
    [queryString],
  );

  const {
    isFetching,
    data: { detailedReport, count } = {
      detailedReport: [],
      count: { total: 0, available: 0, assigned: 0 },
    },
  } = useGetCouponPoolStatistics(params);

  return (
    <MainLayout title="Statystyki" headerButtons={[]}>
      <Stack spacing={4}>
        <Search />
        {isFetching && <LoadingIndicator />}
        {!isFetching && !isEmpty(params) && (
          <>
            <StatBox {...count} />
            <Chart detailedReport={detailedReport} />
          </>
        )}
      </Stack>
    </MainLayout>
  );
}

export default Statistics;
