import { useQuery } from 'react-query';
import qs from 'query-string';
import { reject } from 'lodash';
import { API, APIRoutes, DEFAULT_STALE_TIME, EXTENDED_STALE_TIME } from 'api';
import { OptionType } from 'types/common';

const fetchCouponPools = async (queryString: string) => {
  const { data: response } = await API.get(
    APIRoutes.couponPools.index(queryString),
  );
  return {
    data: reject(response?.data, { label: 'welcome' }),
    totals: response?.totalCount,
  };
};

const fetchCouponPool = async (id: string) => {
  const { data } = await API.get(APIRoutes.couponPools.byId(id));
  return data;
};

const useGetCouponPools = (queryString: string) =>
  useQuery<CouponPoolsResponse>(
    ['couponPools', queryString],
    () => fetchCouponPools(queryString),
    {
      staleTime: DEFAULT_STALE_TIME,
    },
  );

const useGetCouponPool = (id: string) =>
  useQuery<CouponPoolResponse>(['couponPool', id], () => fetchCouponPool(id));

const useGetCouponPoolsOptions = () =>
  useQuery<CouponPoolsResponse, unknown, OptionType[]>(
    ['couponPoolsOptions'],
    () => {
      const queryString = qs.stringify({
        limit: 99999,
      });
      return fetchCouponPools(`?${queryString}`);
    },
    {
      staleTime: EXTENDED_STALE_TIME,
      select: ({ data }) =>
        data?.map((pool) => ({
          label: pool.label,
          value: pool._id,
        })),
    },
  );

export { useGetCouponPools, useGetCouponPool, useGetCouponPoolsOptions };
