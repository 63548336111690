import { DrawerBody, DrawerFooter, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import Papa from 'papaparse';
import FormBody from 'pages/Coupons/Form/Import/components/FormBody';
import {
  BODY_SCHEMA,
  DEFAULT_VALUES,
} from 'pages/Coupons/Form/Import/constants';
import { useFormWithSchema } from 'utils/formHooks';
import FormButtons from 'components/FormButtons';
import { useFormDrawer } from 'context/FormDrawerContext';
import notify from 'utils/notify';
import { API, APIRoutes } from 'api';

function CouponsImport() {
  const queryClient = useQueryClient();
  const { closeFormDrawer } = useFormDrawer();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const { mutate: importCoupons, isLoading } = useMutation({
    mutationKey: 'couponsMutation',
    mutationFn: (data: string) =>
      API.post(APIRoutes.coupons.import, data, {
        headers: {
          'Content-Type': 'text/plain',
        },
      }),
    onSuccess: () => {
      closeFormDrawer();
      notify('success', 'Importowanie kuponów zakończyło się sukcesem');
      queryClient.invalidateQueries('coupons');
    },
    onError: () => {
      notify(
        'error',
        'Wystąpił problem podczas importowania kuponów. Upewnij się, że plik ma właściwą strukturę i nie zawiera duplikatów',
      );
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    Papa.parse(data.file as File, {
      complete: (results) =>
        importCoupons(Papa.unparse(results.data, { delimiter: ';' })),
    });
  });

  return (
    <>
      <DrawerBody>
        <FormProvider {...methods}>
          <VStack
            w="100%"
            h="100%"
            as="form"
            id="coupons-import-form"
            onSubmit={onSubmit}
            justify="space-between"
          >
            <FormBody isLoading={isLoading} />
          </VStack>
        </FormProvider>
      </DrawerBody>
      <DrawerFooter>
        <FormButtons
          onCancel={closeFormDrawer}
          isLoading={isLoading}
          isDisabled={!isValid}
          formId="coupons-import-form"
        />
      </DrawerFooter>
    </>
  );
}

export default CouponsImport;
