const FormError = {
  parts: ['text'],
  baseStyle: {
    text: {
      color: 'complementary.error',
      fontSize: '14px',
      lineHeight: '20px',
      marginTop: '8px',
    },
  },
};

export default FormError;
