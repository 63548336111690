import { Box, VStack } from '@chakra-ui/react';
import Logo from 'components/Icons/Logo';
import { SIDEBAR_WIDTH } from 'components/Layout/constants';
import Navigation from 'components/Navigation';
import Footer from 'components/Sidebar/Footer';

function Sidebar() {
  return (
    <Box
      width="100%"
      maxWidth={SIDEBAR_WIDTH}
      backgroundColor="complementary.richBlack"
      height="100vh"
      position="sticky"
      zIndex={100}
      top={0}
      left={0}
      color="complementary.white"
      flexShrink={0}
    >
      <VStack w="100%" h="100%" justify="space-between">
        <Box width="100%" px={4} py={6}>
          <Logo />
        </Box>
        <Navigation />
        <Footer />
      </VStack>
    </Box>
  );
}

export default Sidebar;
