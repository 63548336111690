import styled from '@emotion/styled';

const Tr = styled.tr`
  :not(:last-child) {
    border-bottom: solid 1px #eef2fb;
  }

  td {
    font-weight: 400;
    line-height: 24px;
    vertical-align: middle;
    padding: 16px 8px;

    :first-of-type,
    :last-of-type {
      padding-inline: 0px;
    }
  }
`;

export default Tr;
