import { Box, Stack } from '@chakra-ui/react';
import LoadingSpinner from 'components/LoadingSpinner';
import { FormBodyProps } from 'types/common';
import Input from 'components/Form/Input';
import PasswordInput from 'components/Form/PasswordInput';

function FormBody({ isLoading, isEditMode }: FormBodyProps) {
  return (
    <Box w="100%">
      {isLoading && <LoadingSpinner />}
      <Stack spacing={4}>
        {!isEditMode && (
          <Input
            name="email"
            label="Adres e-mail"
            placeholder="Wpisz adres e-mail"
          />
        )}
        <PasswordInput
          name="password"
          label="Hasło"
          placeholder="Wpisz hasło"
        />
        {/* <HStack spacing={2} alignItems="end">
          <Input name="apiKey" label="API key" placeholder="Wpisz API key" />
          <Button
            variant="outlined"
            onClick={() => setValue('apiKey', `${uuidv4()}-${uuidv4()}`)}
          >
            Generuj
          </Button>
        </HStack> */}
      </Stack>
    </Box>
  );
}

export default FormBody;
