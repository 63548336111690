import { useIsMutating } from 'react-query';
import MainLayout from 'components/Layout/MainLayout';
import Table from 'pages/CouponPools/Listing/components/Table';
import LoadingIndicator from 'components/LoadingIndicator';
import { useGetCouponPools } from 'api/couponPools';
import Add from 'components/Icons/Add';
import useQueryParams from 'utils/useQueryParams';
import Footer from 'components/Footer';
import { useFormDrawer } from 'context/FormDrawerContext';
import CouponPoolCreate from 'pages/CouponPools/Form/Create';

function CouponPools() {
  const { queryString } = useQueryParams({
    limit: '10',
    page: '1',
  });
  const { setFormDrawer } = useFormDrawer();

  const isMutating = useIsMutating({
    mutationKey: ['couponPoolsMutation'],
  });

  const {
    isFetching,
    data: { data: coupons, totals } = { data: [], totals: 0 },
  } = useGetCouponPools(queryString);

  const showLoading = isMutating || isFetching;

  return (
    <MainLayout
      title="Pule kuponów"
      headerButtons={[
        {
          label: 'Dodaj',
          leftIcon: <Add />,
          onClick: () =>
            setFormDrawer({
              title: 'Nowa pula kodów',
              content: <CouponPoolCreate />,
            }),
        },
      ]}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && <Table data={coupons} />}
      <Footer totals={totals} />
    </MainLayout>
  );
}

export default CouponPools;
