import { Icon, IconProps } from '@chakra-ui/react';

function Import(props: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_280_2231"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_280_2231)">
        <path
          d="M18 20.6442L21.6442 17L20.6 15.9558L18.75 17.8058V13.275H17.25V17.8058L15.4 15.9558L14.3558 17L18 20.6442ZM14.25 23.75V22.25H21.75V23.75H14.25ZM6.05768 19.75C5.56537 19.75 5.14102 19.5718 4.7846 19.2154C4.4282 18.859 4.25 18.4346 4.25 17.9423V4.05772C4.25 3.56542 4.4282 3.14106 4.7846 2.78465C5.14102 2.42825 5.56537 2.25005 6.05768 2.25005H13L18.75 8V11.0827H17.25V8.74997H12.25V3.74997H6.05768C5.98074 3.74997 5.91022 3.78203 5.84612 3.84615C5.78201 3.91025 5.74995 3.98076 5.74995 4.0577V17.9423C5.74995 18.0192 5.78201 18.0897 5.84612 18.1538C5.91022 18.218 5.98074 18.25 6.05768 18.25H12.0577V19.75L6.05768 19.75Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}

export default Import;
