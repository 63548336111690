import { Box, Button, Stack } from '@chakra-ui/react';
import Logout from 'components/Icons/Logout';
import { useAuth } from 'context/AuthContext';

function Footer() {
  const { logout } = useAuth();

  return (
    <Stack w="100%" align="start" spacing={0}>
      {/* <Box w="100% " padding="24px 16px">
        <Button
          variant="unstyled"
          leftIcon={<Icon as={ICON_DICT[currentStore]} fontSize="24px" />}
          display="flex"
          textTransform="none"
          fontWeight={400}
          fontSize="16px"
          height="auto"
          onClick={onOpen}
        >
          {LABEL_DICT[currentStore]}
        </Button>
      </Box> */}
      <Box w="100%" padding="24px 16px">
        <Button
          variant="unstyled"
          leftIcon={<Logout />}
          display="flex"
          textTransform="none"
          fontWeight={400}
          fontSize="16px"
          height="auto"
          onClick={logout}
        >
          Wyloguj się
        </Button>
      </Box>
      {/* <StoreChangeModal
        onClose={onClose}
        isOpen={isOpen}
        currentStore={currentStore}
      /> */}
    </Stack>
  );
}

export default Footer;
