import { Box, Button, HStack, Stack, Text } from '@chakra-ui/react';

interface FilterBoxProps {
  filterForm: React.ReactNode;
  filters?: React.ReactNode;
}

function FilterBox({ filterForm, filters }: FilterBoxProps) {
  return (
    <Stack spacing={4} mb={6}>
      <Box bgColor="complementary.white" borderRadius="6px" padding="16px">
        <HStack spacing={4} w="100%">
          {filterForm}
          <Button
            variant="outlined"
            type="submit"
            form="filter-form"
            flexShrink={0}
          >
            Filtruj
          </Button>
        </HStack>
      </Box>
      {filters && (
        <HStack spacing={2}>
          <Text>Wybrane filtry:</Text>
          {filters}
        </HStack>
      )}
    </Stack>
  );
}

FilterBox.defaultProps = {
  filters: null,
};

export default FilterBox;
