import { DrawerBody, DrawerFooter, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useEffect } from 'react';
import FormBody from 'pages/CouponPools/Form/components/FormBody';
import {
  BODY_SCHEMA,
  DEFAULT_VALUES,
  FormData,
} from 'pages/CouponPools/Form/constants';
import { API, APIRoutes } from 'api';
import FormButtons from 'components/FormButtons';
import { useFormWithSchema } from 'utils/formHooks';
import { useFormDrawer } from 'context/FormDrawerContext';
import notify from 'utils/notify';
import { useGetCouponPool } from 'api/couponPools';

function CouponPoolEdit({ id }: { id: string }) {
  const queryClient = useQueryClient();
  const { closeFormDrawer } = useFormDrawer();

  const { data: { data: couponPool } = {}, isFetching } = useGetCouponPool(id);

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    formState: { isValid },
    reset,
  } = methods;

  const { mutate: editCouponPool, isLoading: isEditing } = useMutation({
    mutationKey: 'couponPoolsMutation',
    mutationFn: (data: FormData) =>
      API.patch(APIRoutes.couponPools.byId(id), data),
    onSuccess: () => {
      closeFormDrawer();
      notify('success', 'Zmiany w puli kuponów zostały zastosowane');
      queryClient.invalidateQueries('couponPools');
    },
    onError: () => {
      notify('error', 'Wystąpił problem podczas edycji puli kuponów');
    },
  });

  const onSubmit = handleSubmit(async (data: FormData) => editCouponPool(data));

  useEffect(() => {
    if (couponPool) reset({ ...DEFAULT_VALUES, ...couponPool });
  }, [couponPool, reset]);

  const isLoading = isFetching || isEditing;

  return (
    <>
      <DrawerBody>
        <FormProvider {...methods}>
          <VStack
            w="100%"
            h="100%"
            as="form"
            id="coupon-pool-editor"
            onSubmit={onSubmit}
            justify="space-between"
          >
            <FormBody
              isLoading={isLoading}
              poolLabel={couponPool?.label}
              isEditMode
            />
          </VStack>
        </FormProvider>
      </DrawerBody>
      <DrawerFooter>
        <FormButtons
          onCancel={closeFormDrawer}
          isLoading={isLoading}
          isDisabled={!isValid}
          formId="coupon-pool-editor"
        />
      </DrawerFooter>
    </>
  );
}

export default CouponPoolEdit;
