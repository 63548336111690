import { AccordionButton, AccordionItem, Text, HStack } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import colors from 'theme/colors';

interface NavItemProps {
  icon?: React.ReactNode;
  title: string;
  link: string;
}

function NavItem({ icon, title, link }: NavItemProps) {
  return (
    <AccordionItem position="relative" border="none">
      <NavLink to={link}>
        {({ isActive }) => (
          <AccordionButton
            fontSize="16px"
            padding={4}
            {...(isActive && {
              background: `${colors.complementary.hoverBlue}73`,
            })}
            _hover={{
              background: `${colors.complementary.hoverBlue}4D`,
            }}
          >
            <HStack spacing={2}>
              {icon}
              <Text>{title}</Text>
            </HStack>
          </AccordionButton>
        )}
      </NavLink>
    </AccordionItem>
  );
}
NavItem.defaultProps = {
  icon: undefined,
};

export default NavItem;
